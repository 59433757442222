import { useState, Fragment } from "react";
import Stepper from "component/Stepper";
import EmailVerificationOtp from "dialogs/EmailVerificationOtp";
import InfoDialog from "dialogs/InfoDialog";
import { EyeIcon, EyeOffIcon, InfoIcon } from "assets";
import { sendSignUpOtp, signUpService } from "services/Auth";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Paths } from "routes";
import Login from "dialogs/Login";
import ForgotPasswordVerifyEmail from "dialogs/ForgotPassVerifyEmail";

const SignSteps = ["Personal Info", "Company Details", "AI Suggestions"];

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export default function IndexV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const paramsEmail = searchParams.get('email');

  const initialValues = {
    firstname: "",
    lastname: "",
    email: paramsEmail || "",
    password: "",
    confirmpassword: "",
    companyName: "",
    industry: "",
    manualDetail: "",
    lookingJobTitles: "",
  };

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [verificationOtpPopup, setVerificationOtpPopup] = useState(false);
  const [verificationInfoPopup, setVerificationInfoPopup] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [forgotPasswordPopup, setForgotPassword] = useState(false);
  const [verificationProgessInfoPopup, setVerificationProgessInfoPopup] =
    useState(false);

  const errorsField = {
    firstname: "First name",
    lastname: "Last name",
    email: "Email",
    password: "Password",
    confirmpassword: "Confirm password",
    companyName: "Company name",
    industry: "Industry",
    lookingJobTitles: "Job title",
  };
  const validateStep = (step) => {
    let newErrors = {};

    if (step === 0) {
      if (!formValues.firstname) newErrors.firstname = "First name is required";
      if (!formValues.lastname) newErrors.lastname = "Last name is required";
      if (!formValues.email) {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(formValues.email)) {
        newErrors.email = "Please enter a valid email address";
      }
      if (!formValues.password) newErrors.password = "Password is required";
      if (!formValues.confirmpassword)
        newErrors.confirmpassword = "Confirm password is required";
      else if (formValues.password !== formValues.confirmpassword)
        newErrors.confirmpassword = "Passwords do not match";
    }

    if (step === 1) {
      if (!formValues.companyName)
        newErrors.companyName = "Company name is required";
      if (!formValues.industry) newErrors.industry = "Industry is required";
      if (!formValues.lookingJobTitles)
        newErrors.lookingJobTitles = "Job title is required";
    }

    // if (step === 2) {
    //   if (!formValues.manualDetail)
    //     newErrors.manualDetail = "Conversation features are required";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (validateStep(step)) {
      if (step === 2){
        setVerificationOtpPopup(true);
        try {
          await sendSignUpOtp({
            FirstName: formValues.firstname,
            LastName: formValues.lastname,
            Password: formValues.password,
            Email: formValues.email,
            CaptchaCode:"Captcha"
          })
        } catch (err) {
          console.log("error", err);
        }
      } 
      else setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleFieldChange = (fieldName, value) => {
    const newValues = { ...formValues, [fieldName]: value };
    setFormValues(newValues);

    // Real-time validation for changed field
    let newErrors = { ...errors };
    if (fieldName === "email") {
      if (!value) {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(value)) {
        newErrors.email = "Please enter a valid email address";
      } else {
        delete newErrors.email;
      }
    } else if (fieldName === "confirmpassword") {
      if (newValues.password !== newValues.confirmpassword) {
        newErrors.confirmpassword = "Passwords do not match";
      } else {
        delete newErrors[fieldName];
      }
    } else {
      if (!value) {
        newErrors[fieldName] = `${errorsField[fieldName]} is required`;
      } else {
        delete newErrors[fieldName];
      }
    }

    setErrors(newErrors);
  };

  const renderInputField = (name, placeholder, label, type = "text") => (
    <div className="flex flex-col gap-1">
      {renderLabel(label)}
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={formValues[name]}
        onChange={(e) => {
          setFormValues({ ...formValues, [name]: e.target.value });
          handleFieldChange(name, e.target.value);
        }}
        className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
      />
      {errors[name] && (
        <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {errors[name]}
        </div>
      )}
    </div>
  );

  const renderLabel = (label) => (
    <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px] ">
      {label}
    </label>
  );

  const handleSignUp = async () => {
    try {
      setLoading(true);
      const userData = await signUpService({
        FirstName: formValues.firstname,
        LastName: formValues.lastname,
        Password: formValues.password,
        Email: formValues.email,
        CompanyName: formValues.companyName,
        Industry: formValues.industry,
        JobTitleList: formValues.lookingJobTitles,
        AiSuggestions: formValues.manualDetail,
        Otp: "000000", // [Otp validation already done so this is not needed]
      });
      setLoading(false);
      setVerificationOtpPopup(false);
      if (userData?.data?.Status === "review") {
        setVerificationInfoPopup(true);
      }
    } catch (error) {
      setLoading(false);
      setVerificationOtpPopup(false);
      if (error?.response?.data?.code === "email_already_exists") {
        setVerificationProgessInfoPopup(true);
      } else {
        setVerificationInfoPopup(true);
      }
    }
  };

  const handleResendotp =async() =>{
    try {
      await sendSignUpOtp({
        FirstName: formValues.firstname,
        LastName: formValues.lastname,
        Password: formValues.password,
        Email: formValues.email,
        CaptchaCode:"Captcha"
      })
    } catch (err) {
      console.log("err", err);
    }
  }

  return (
    <div className="w-full sm:max-w-[420px] max-w-full mx-auto md:px-0 px-4">
      <h4 className="my-7 text-[#2E2E2E] text-2xl text-center font-medium leading-7 -tracking-[0.24px] ">
        Sign up
      </h4>

      <div className="flex flex-col gap-7">
        <Stepper selectedStep={step} steps={SignSteps} />

        {loginPopup && <Login open={loginPopup} setOpen={setLoginPopup} handleForgotPassword={() => {
          setForgotPassword(true);
          setLoginPopup(false);
        }}/>}

        <ForgotPasswordVerifyEmail open={forgotPasswordPopup} setOpen={setForgotPassword} />

        {verificationOtpPopup && (
          <EmailVerificationOtp
            open={verificationOtpPopup}
            setOpen={setVerificationOtpPopup}
            handleSubmit={handleSignUp}
            loading={loading}
            email={formValues.email}
            handleResend={handleResendotp}
          />
        )}
        {verificationInfoPopup && (
          <InfoDialog
            open={verificationInfoPopup}
            setOpen={setVerificationInfoPopup}
            infoDetail={{
              heading: "Verification",
              innerContent:
                "We're verifying your application, this usually takes 24hours. Once we verify your data we'll send you an email with a login link.",
            }}
            handleSubmit={() => {
              setFormValues(initialValues);
              setStep(0);
              setLoginPopup(true);
            }}
            icon={InfoIcon}
          />
        )}
        {verificationProgessInfoPopup && (
          <InfoDialog
            open={verificationProgessInfoPopup}
            setOpen={setVerificationProgessInfoPopup}
            infoDetail={{
              heading: "This email address is already under review",
              innerContent: "",
            }}
            handleSubmit={() => {
              setFormValues(initialValues);
              setStep(0);
              setLoginPopup(true);
            }}
            icon={InfoIcon}
          />
        )}
        <div className="flex flex-col gap-[52px]">
          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex flex-col gap-[18px]"
          >
            {step === 0 && (
              <>
                <div className="flex md:flex-row flex-col gap-5">
                  {renderInputField("firstname", "Ex John", "First Name")}
                  {renderInputField("lastname", "Ex Doe", "Last Name")}
                </div>
                {renderInputField(
                  "email",
                  "Ex john.doe@example.com",
                  "Work Email Address"
                )}
                <div className="relative">
                  {renderInputField(
                    "password",
                    "Password",
                    "Password",
                    showPassword ? "text" : "password"
                  )}
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className={`absolute right-4 top-1/2 cursor-pointer ${
                      errors["password"] ? "-translate-y-1/2" : ""
                    }`}
                  >
                    <img src={showPassword ? EyeOffIcon : EyeIcon} alt="eye" />
                  </span>
                </div>
                <div className="relative">
                  {renderInputField(
                    "confirmpassword",
                    "Confirm Password",
                    "Confirm Password",
                    showConfirmPassword ? "text" : "password"
                  )}
                  <span
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className={`absolute right-4 top-1/2 cursor-pointer ${
                      errors["confirmpassword"] ? "-translate-y-1/2" : ""
                    }`}
                  >
                    <img
                      src={showConfirmPassword ? EyeOffIcon : EyeIcon}
                      alt="eye"
                    />
                  </span>
                </div>
              </>
            )}

            {step === 1 && (
              <>
                {renderInputField(
                  "companyName",
                  "Company Name",
                  "Company Name"
                )}
                {renderInputField("industry", "Industry", "Industry")}
                <div className="flex flex-col gap-1">
                  {renderLabel("Typical Job Titles you Hire for")}
                  <textarea
                    name="lookingJobTitles"
                    rows="5"
                    placeholder={`Ex Scrum Master, Python Developer..."`}
                    value={formValues.lookingJobTitles || ""}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        lookingJobTitles: e.target.value,
                      });
                      handleFieldChange("lookingJobTitles", e.target.value);
                    }}
                    className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
                  />
                  {errors.lookingJobTitles && (
                    <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                      {errors.lookingJobTitles}
                    </div>
                  )}
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-1">
                    <h2 className="text-base text-[#1F1F1F] leading-[22px] -tracking-[1%] font-medium ">
                      Conversation features
                    </h2>
                    <h3 className="text-[#1F1F1F] text-[13px] leading-5 -tracking-[1%] font-light ">
                      What key conversation features would you expect a typical
                      conversation between yourself and a potential candidate
                      have?
                    </h3>
                  </div>
                  <textarea
                    name="manualDetail"
                    rows="7"
                    placeholder={`Ex "Ask if they'd be down to take a salary reduction for work life balance"`}
                    value={formValues.manualDetail || ""}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        manualDetail: e.target.value,
                      });
                      handleFieldChange("manualDetail", e.target.value);
                    }}
                    className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
                  />
                  {errors.manualDetail && (
                    <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                      {errors.manualDetail}
                    </div>
                  )}
                </div>
              </>
            )}

            {errors.submit && (
              <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                {errors.submit}
              </div>
            )}
          </form>
          <div>
            <div className="w-full flex  justify-between flex-row gap-3 sm:gap-0 pb-[34px]">
              <button
                type="button"
                className={` btn bg-white border-custom-primary border-[1px] text-black border-purple font-semibold py-[14px] px-[32px] rounded-lg ${
                  step === 0 ? "opacity-40 cursor-not-allowed" : ""
                }`}
                onClick={handleBack}
                disabled={step === 0}
              >
                Back
              </button>
              <button
                type="button"
                className=" btn bg-custom-primary text-white font-semibold py-[14px] px-[32px] rounded-lg disabled:opacity-75"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
            {/* <span
              className="text-[#121212] text-[14px] leading-5 font-semibold pb-12 cursor-pointer text-right mx-auto block"
              onClick={() => navigate(Paths.Login)}
            >
              Already a member?
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
import React, { Fragment, useEffect, useState } from "react";
import Papa from "papaparse";
import { CrossIcon, UploadIcon, FileUploadIcon } from "assets";
import { resetUploadedFileValue } from "utils/common";

export default function FileUpload({ fileInfo, setFileInfo, setUploadedCandidates }) {
  const [error, setError] = useState("");

  useEffect(() => {
    return () => {
      resetUploadedFileValue()
      setError("")
    }
  }, [])
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate the file type
      if (!file.name.endsWith(".csv")) {
        setError("Only .CSV files are allowed");
        setFileInfo({ name: "", size: 0 });
        return;
      }
  
      // Parse the CSV file
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const headers = results.meta.fields.map((header) =>
            header.trim().toLowerCase()
          );
          const requiredHeaders = ["firstname", "lastname", "phonenumber"];
  
          // Check if all required headers are present
          const missingHeaders = requiredHeaders.filter(
            (header) => !headers.includes(header)
          );
  
          if (missingHeaders.length > 0) {
            setError(
              `CSV file is missing the following required headers: ${missingHeaders.join(
                ", "
              )}`
            );
            setFileInfo({ name: "", size: 0 });
          } else {
            const sizeInKB = (file.size / 1024).toFixed(2);
            setFileInfo({ name: file.name, size: sizeInKB });
            setError("");
  
            // UK and India phone number regexes
            const ukPhoneRegex = /^(\+44|0)7\d{9}$/;
            const indiaPhoneRegex = /^(\+91|0)?[6-9]\d{9}$/;
  
            const jsonData = results.data.map(row => ({
              firstName: row['firstname'] || row['firstName'] || row['FirstName'] || '',
              lastName: row['lastname'] || row['lastName'] || row['LastName'] || '',
              phoneNumber: (row['phonenumber'] || row['phoneNumber'] || row['PhoneNumber'] || '')
                .replace(/\s+|[\(\)]/g, '') // Remove spaces and brackets
            }));
  
            // Filter out rows with empty values or invalid UK/India phone numbers
            const validJsonData = jsonData.filter(row => 
              row.firstName && row.lastName && 
              (ukPhoneRegex.test(row.phoneNumber) || indiaPhoneRegex.test(row.phoneNumber))
            );
  
            if (validJsonData.length === 0) {
              setError("No valid UK or Indian phone numbers found.");
              setFileInfo({ name: "", size: 0});
            } else {
              console.log("validJsonData", validJsonData);
              
              setUploadedCandidates(validJsonData);
              setError("");
            }
          }
        },
        error: () => {
          setError("There was an error parsing the CSV file");
          setFileInfo({ name: "", size: 0 });
        },
      });
    } else {
      setFileInfo({ name: "", size: 0 });
    }
  };

  const handleFileRemove = () => {
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    setFileInfo({ name: "", size: 0 });
    setError(""); // Clear error on file removal
  };

  return (
    <Fragment>
      <label
        htmlFor="file-upload"
        className="border border-dashed border-[#35353599] bg-[#F9F9F9] rounded-lg min-h-[220px] flex flex-col justify-center items-center cursor-pointer"
      >
        <input
          id="file-upload"
          type="file"
          accept=".csv"
          className="hidden"
          onChange={handleFileChange}
        />
        {fileInfo.name ? (
          <div className="flex justify-between items-center bg-[#2764D214] py-[14px] px-3 w-full max-w-[calc(100%_-_48px)] rounded-lg">
            <div className="flex items-center w-full gap-[6px]">
              <img
                src={FileUploadIcon}
                alt="FileUploadIcon"
                className="w-5 h-5"
              />
              <span className="text-sm text-gray-600">
                Selected file: {fileInfo.name} ({fileInfo.size} KB)
              </span>
            </div>
            <div
              className="w-6 h-6 flex justify-center items-center"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from triggering the file input
                e.preventDefault();
                handleFileRemove();
              }}
            >
              <img src={CrossIcon} alt="CancelIcon" className="w-full h-full" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <img src={UploadIcon} alt="UploadIcon" width={20} height={20}/>
            <span className="block text-sm font-medium text-[#121212] mt-2">
              Drag & Drop your file here or Browse file
            </span>
            <p className="text-xs text-[#353535]">
              (File must be in .CSV format)
            </p>
          </div>
        )}
      </label>
      {error && (
        <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {error}
        </span>
      )}
    </Fragment>
  );
}
